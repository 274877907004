import React, { useContext, useEffect, useState } from "react";
import { useApi } from "../../api";
import {
  LexicalMatch,
  Permissions,
  ProjectDimension,
  ProjectDimensionClasseDistribution,
  VerbatimPaginated,
  VerbatimViewWithTagTokens,
} from "../../app_client";
import { Badge, Modal, Spinner } from "flowbite-react";
import { useParams, useSearchParams } from "react-router-dom";
import { YMButton, YMCard, YMLoader, YMTinyButton } from "../../ym-components";
import { ProjectApi, useProjectApi, useProjectQuery } from "./State";
import { DistributionDataviz } from "./dimension/DistributionDataviz";
import { AiOutlineDownload } from "react-icons/ai";
import { AuthUserContext } from "../../AuthUserContext";
import { VerbatimList } from "./components/verbatims/VerbatimLists";
import { SiCodereview } from "react-icons/si";
import { FrequencyGraphCanvas } from "./components/frequencyGraph/FrequencyGraphCanvas";
import { ButtonDeleteDimension } from "./components/ButtonDeleteDimension";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RxMagicWand } from "react-icons/rx";
import { SingletonDimensions } from "../../util";
import { CgArrowAlignH, CgMergeVertical } from "react-icons/cg";
import {
  MdDelete,
  MdEdit,
  MdOutlinePublishedWithChanges,
  MdSearch,
} from "react-icons/md";
import { FewShotLearningModelInfo } from "./components/FewShotLearningModelInfo";
import { DimensionTagPatchModal } from "./dimension/DimensionTagPatch";
import { notificationInfo } from "../../toast-notification";
import { DimensionTagRenameModal } from "./dimension/DimensionTagRename";

const DimensionMatchesDistribution = (props: {
  projectId: string;
  title: string;
  description: undefined | string;
  dimName: string;
  dimType: string;
  dimModelId: string;
  dimension: ProjectDimension;
  onDeleteTags: any;
}) => {
  const { api, isAuthenticated } = useApi();
  const projectApi = useProjectApi() as ProjectApi;
  const { permissions } = useContext(AuthUserContext);
  const { statKind } = useParams();
  const [stats, setStats] = useState(
    "loading" as
      | (ProjectDimensionClasseDistribution & { total: number })[]
      | "loading"
  );
  const [stat, setStat] = useState(
    null as (ProjectDimensionClasseDistribution & { total: number }) | null
  );
  const [version, setVersion] = useState(0); // used to force refresh of the dataviz
  const [selectedIndex, setSelectedIndex] = useState([] as number[]);

  const [grandTotal, setGrandTotal] = useState(0);
  const [tagCount, setTagCount] = useState(0);

  const [verbatims, setVerbatims] = useState(
    "loading" as VerbatimViewWithTagTokens[] | "loading"
  );

  const [displayVerbatimModal, setDisplayVerbatimModal] = useState(false);
  const [displayDimensionPatchModal, setDisplayDimensionPatchModal] =
    useState(false);
  const [verbatimModalParams, setVerbatimModalParams] = useState<{
    dimension: string;
    classname: string;
    lemmas: string;
    orderByClassScore?: string;
  }>({
    dimension: "",
    classname: "",
    lemmas: "",
  });

  useEffect(() => {
    if (stats === "loading") return;
    if (!stat) setStat(stats[0]);
    setGrandTotal(stats.reduce((acc, val) => acc + val.total, 0));
    setTagCount(stats.reduce((acc, val) => acc + val.matches.length, 0));
  }, [stats]);

  // search verbatims by classname
  useEffect(() => {
    if (!stat) return;
    setVerbatims("loading");
    api
      .list_verbatims(
        props.projectId,
        undefined,
        undefined,
        props.dimension,
        stat.className,
        undefined,
        stat.className,
        1,
        undefined,
        false,
        undefined
      )
      .then((verbatims: VerbatimPaginated) => {
        setVerbatims(verbatims.data);
      });
  }, [stat]);

  const fetchStats = async () => {
    const statClassName = stat?.className;
    if (isAuthenticated === true && api && props.projectId) {
      let stats: ProjectDimensionClasseDistribution[] =
        await api.project_dimension_matches_distribution(
          props.projectId,
          props.dimName
        );

      // transformer NER stats to match the same interface as the other stats
      if (props.dimension.mono_class) {
        stats = [
          {
            name: props.title,
            className: props.dimName,
            counts: stats.reduce(
              (agg, stat) => [...agg, ...stat.counts],
              [] as number[]
            ),
            matches: stats.reduce(
              (agg, stat) => [...agg, ...stat.matches],
              [] as string[]
            ),
          },
        ];
      }

      const stats_with_total = stats.map((stat) => ({
        ...stat,
        total: stat.counts.reduce((acc, val) => acc + val, 0),
      }));
      if (statClassName) {
        setStat(
          stats_with_total.find((stat) => stat.className == statClassName) ||
            null
        );
      }
      setStats(stats_with_total);
    }
    setVersion(version + 1);
  };

  useEffect(() => {
    setStats("loading");
    fetchStats();
  }, [api, isAuthenticated, props.projectId, props.dimension]);

  const handleSearchVerbatims = (classname: string) => {
    if (stats === "loading") return;
    let stat = stats.find((stat) => stat.className == classname);
    if (!stat) return;
    let lemmas = stat?.matches
      .filter((_, i) => selectedIndex.indexOf(i) >= 0)
      .join(",");
    // transformer NER stats to match the same interface as the other stats
    if (props.dimension.mono_class) {
      setVerbatimModalParams({
        dimension: props.dimension.name,
        classname: lemmas,
        lemmas: "",
      });
    } else {
      setVerbatimModalParams({
        dimension: props.dimension.name,
        classname: classname,
        lemmas: lemmas,
        orderByClassScore: classname,
      });
    }

    setDisplayVerbatimModal(true);
  };

  const handleClickOnTag = (i: number) => {
    if (selectedIndex.indexOf(i) >= 0) {
      setSelectedIndex(selectedIndex.filter((index) => index != i));
    } else {
      setSelectedIndex([...selectedIndex, i]);
    }
  };

  const [mergeTagModal, setMergeTagModal] = useState(false);
  const [renameTagModal, setRenameTagModal] = useState(false);
  const [currentTagName, setCurrentTagName] = useState("");
  const [mergeTagTargetIndex, setMergeTargetIndex] = useState(0);
  const mergeSimilarTags = useMutation({
    mutationFn: () => {
      if (stat != null) {
        const selected = selectedIndex.map((i) =>
          stat.matches[i].toLowerCase()
        );
        const targetName = stat.matches[mergeTagTargetIndex];

        // TODO: remove this hack
        // transformer NER stats to match the same interface as the other stats
        let targetLabel = stat.className;
        if (SingletonDimensions.indexOf(props.dimName) != -1) {
          targetLabel = targetName;
        }

        return projectApi.mergeVerbatimTag(
          props.projectId,
          props.dimension.name,
          targetLabel,
          targetName,
          selected
        );
      } else {
        return Promise.resolve(undefined);
      }
    },
    onSuccess: (data) => {
      fetchStats();
      setSelectedIndex((p) => []);
      setMergeTagModal(false);
    },
  });
  const handleMergeTags = async (className: string) => {
    if (stat && stat.matches.length > 0) {
      mergeSimilarTags.mutate();
    }
  };

  const unmergeTag = useMutation({
    mutationFn: () => {
      if (stat != null) {
        const selected = selectedIndex.map((i) =>
          stat.matches[i].toLowerCase()
        );
        return projectApi.unmergeVerbatimTag(
          props.projectId,
          props.dimension.name,
          selected,
          !!props.dimension.mono_class
        );
      } else {
        return Promise.resolve(undefined);
      }
    },
    onSuccess: (data) => {
      fetchStats();
      setSelectedIndex((p) => []);
    },
  });
  const handleUnmergeTags = async (className: string) => {
    if (stat && stat.matches.length > 0) {
      unmergeTag.mutate();
    }
  };

  const handleDeleteTags = async (classname: string) => {
    if (stats === "loading") return;
    let stat = stats.find((stat) => stat.className == classname);
    await Promise.all(
      selectedIndex.map(async (i) => {
        const { matches } = stat as ProjectDimensionClasseDistribution;
        if (props.dimension.mono_class) {
          await api.delete_verbatim_tag_by_classname(
            props.projectId,
            props.dimension.name,
            matches[i],
            matches[i]
          );
        } else {
          await api.delete_verbatim_tag_by_classname(
            props.projectId,
            props.dimension.name,
            classname,
            matches[i]
          );
        }
      })
    );
    setSelectedIndex([]);
    await fetchStats();
    props.onDeleteTags();
  };

  const handleDownloadTrainDataset = async () => {
    api.download_train_dataset(props.projectId, props.dimName);
  };

  const handleDownloadDistributionData = async (
    group: "by_class" | "by_tag" | "raw"
  ) => {
    api.download_dimension_distribution(props.projectId, props.dimName, group);
  };

  const [similarTagsBlackList, setSimilarTagsBlackList] = useState<string[]>(
    []
  );
  const useSearchSimilarTags = useQuery({
    queryKey: [
      "search_similar_tags",
      props.projectId,
      stat ? stat.matches.map((m) => m.toLowerCase()) : "",
    ],
    queryFn: async () => {
      return await projectApi.searchSimilarLexeme(
        props.projectId,
        stat ? stat.matches.map((m) => m.toLowerCase()) : []
      );
    },
    enabled: false,
    staleTime: 0,
  });

  const onSearchSimilarTags = async () => {
    setSimilarTagsBlackList([]);
    await useSearchSimilarTags.refetch();
  };

  const selectedAiSuggestedTags = useSearchSimilarTags.data
    ? useSearchSimilarTags.data.filter(
        (tag) => !similarTagsBlackList.includes(tag.lexeme)
      )
    : [];

  const ignoreAiSuggestionTag = (tag: string) => {
    setSimilarTagsBlackList([...similarTagsBlackList, tag]);
  };

  const updateClassWithAiSuggestion = useMutation({
    mutationFn: async (lexemes: string[]) => {
      if (!stat) return;
      await Promise.all(
        lexemes.map((lexeme) =>
          projectApi.tagAPattern({
            project_id: props.projectId,
            rule: [
              {
                LEMMA: lexeme,
              },
            ],
            dimension: props.dimension.name,
            label: stat?.className,
          })
        )
      );
    },
  });
  const addAiSuggestedTagsToModel = async () => {
    await updateClassWithAiSuggestion.mutateAsync(
      selectedAiSuggestedTags.map((tag) => tag.lexeme)
    );
    setSimilarTagsBlackList([]);
    await fetchStats();
  };

  return (
    <div>
      {stats === "loading" ? (
        <div className="w-100 mt-10 text-center">
          <Spinner color="gray" size="xl" aria-label="Default status example" />
          <div className={"mt-4 text-gray-400"}>
            {" "}
            Chargement des statistiques
          </div>
        </div>
      ) : (
        <div className={"flex flex-col"}>
          <div className={"flex"}>
            <div className={"pl-4 grow"}>
              <div className="flex items-center space-x-2">
                <div className="text-indigo-900 text-2xl capitalize font-bold">
                  {" "}
                  {props.title}{" "}
                </div>
              </div>
              <div className="text-indigo-900">
                <span className="text-purple-700 font-medium">
                  {" "}
                  {grandTotal}{" "}
                </span>{" "}
                occurrences de{" "}
                <span className="text-purple-700 font-medium">
                  {" "}
                  {tagCount}{" "}
                </span>{" "}
                tags
              </div>

              {props.dimType == "fewshot_tagging" && props.dimModelId && (
                <div className="text-indigo-900 mt-2 flex gap-4 items-end">
                  <p> Cette dimension a été tagguée par un modèle FST </p>
                  <div>
                    {" "}
                    <FewShotLearningModelInfo
                      model_id={props.dimModelId}
                    />{" "}
                  </div>
                </div>
              )}
              {props.description && (
                <div className={"w-2/3 text-indigo-900 mt-3 mb-10"}>
                  {props.description}
                </div>
              )}
              <div className={"mt-10"}>
                <DistributionDataviz
                  version={version}
                  dimension={props.dimension}
                  projectId={props.projectId}
                />
              </div>
              <div className={"mt-4 flex gap-4"}>
                {permissions.indexOf(Permissions.INFERENCE_FULL) != -1 && (
                  <YMButton
                    text={"Train Dataset [admin]"}
                    icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
                    size={"xs"}
                    onClick={handleDownloadTrainDataset}
                  />
                )}
                <YMButton
                  text={"Distribution par classe csv"}
                  icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
                  size={"xs"}
                  onClick={() => handleDownloadDistributionData("by_class")}
                />
                <YMButton
                  text={"Distribution par tag csv"}
                  icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
                  size={"xs"}
                  onClick={() => handleDownloadDistributionData("by_tag")}
                />
                <YMButton
                  text={"Tags csv"}
                  icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
                  size={"xs"}
                  onClick={() => handleDownloadDistributionData("raw")}
                />
              </div>
            </div>
            <div>
              <ButtonDeleteDimension
                dimensionName={props.title}
                dimensionId={props.dimension.id}
                projectId={props.projectId}
              />
            </div>
          </div>

          <div className={"flex mt-10"}>
            <div className="border-r border-gray-200 min-h-screen w-56">
              <div className="my-2">
                <div className="text-indigo-800 cursor-pointer hover:font-bold flex items-center font-bold">
                  <div> {props.title} </div>
                </div>
                <div className="pl-4 border-l border-dashed border-indigo-800 ml-2">
                  <div>
                    {stats.map((s) => (
                      <div
                        onClick={() => setStat(s)}
                        className="flex flex-row items-center pb-2 pr-5 group hover:text-indigo-500 cursor-pointer text-indigo-900 font-bold"
                      >
                        <div
                          className={
                            "text-indigo-800 group-hover:text-indigo-500 cursor-pointer " +
                            (s.className == (stat && stat.className)
                              ? "font-bold"
                              : "font-normal")
                          }
                        >
                          {" "}
                          {s.className}{" "}
                          <span className="font-normal text-sm text-indigo-600">
                            {" "}
                            ({s.total}){" "}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={"pl-4 grow"}>
              {stat && (
                <>
                  <div className="flex items-center space-x-2 mt-10">
                    <div className="text-indigo-800 text-lg capitalize font-medium">
                      {" "}
                      {stat.className}{" "}
                    </div>
                  </div>
                  <div className="text-indigo-900">
                    <span className="text-purple-700 font-medium">
                      {" "}
                      {stat.total}{" "}
                    </span>{" "}
                    occurrences de{" "}
                    <span className="text-purple-700 font-medium">
                      {" "}
                      {stat.matches.length}{" "}
                    </span>{" "}
                    tags
                  </div>

                  <div className={"flex gap-2 mt-3"}>
                    {(!useSearchSimilarTags.isFetched ||
                      selectedAiSuggestedTags.length == 0) && (
                      <YMTinyButton
                        size="xs"
                        onClick={() => onSearchSimilarTags()}
                        text={"Recommander des tags avec l'IA"}
                        color={"white"}
                        loading={useSearchSimilarTags.isFetching}
                        icon={
                          <RxMagicWand className={"h-6 w-6 pr-2 indigo-500"} />
                        }
                      />
                    )}
                    {selectedIndex.length == 0 && (
                      <YMTinyButton
                        color="white"
                        size="xs"
                        onClick={() => handleSearchVerbatims(stat.className)}
                        text={`Explorer tous les verbatims de ${stat.className}`}
                        icon={
                          <MdSearch className={"h-6 w-6 pr-2 indigo-500"} />
                        }
                      />
                    )}
                    {selectedIndex.length == 0 && (
                      <YMTinyButton
                        color="white"
                        size="xs"
                        onClick={() => setDisplayDimensionPatchModal(true)}
                        text={`Importer des correctifs`}
                        icon={
                          <MdOutlinePublishedWithChanges
                            className={"h-6 w-6 pr-2 indigo-500"}
                          />
                        }
                      />
                    )}
                    {selectedIndex.length > 0 && (
                      <YMTinyButton
                        color="white"
                        size="xs"
                        onClick={() => handleSearchVerbatims(stat.className)}
                        text={`Explorer les verbatims de ${stat.className} avec les tags sélectionnés`}
                        icon={
                          <MdSearch className={"h-6 w-6 pr-2 indigo-500"} />
                        }
                      />
                    )}
                    {selectedIndex.length > 0 && (
                      <YMTinyButton
                        color="white"
                        size="xs"
                        onClick={() => handleDeleteTags(stat.className)}
                        text={"Supprimer les tags"}
                        icon={
                          <MdDelete className={"h-6 w-6 pr-2 indigo-500"} />
                        }
                      />
                    )}
                    {selectedIndex.length > 1 && (
                      <YMTinyButton
                        color="white"
                        size="xs"
                        onClick={() => {
                          setMergeTagModal(true);
                          setMergeTargetIndex(selectedIndex[0]);
                        }}
                        text={"Grouper les tags"}
                        icon={
                          mergeSimilarTags.isLoading ? (
                            <div className={"pr-2"}>
                              <Spinner size={"sm"} />
                            </div>
                          ) : (
                            <CgMergeVertical
                              className={"h-6 w-6 pr-2 indigo-500"}
                            />
                          )
                        }
                      />
                    )}
                    {selectedIndex.length === 1 && (
                      <>
                        <YMTinyButton
                          color="white"
                          size="xs"
                          onClick={() => {
                            handleUnmergeTags(stat.className);
                          }}
                          text={"Dégrouper les tags"}
                          icon={
                            unmergeTag.isLoading ? (
                              <div className={"pr-2"}>
                                <Spinner size={"sm"} />
                              </div>
                            ) : (
                              <CgArrowAlignH
                                className={"h-6 w-6 pr-2 indigo-500"}
                              />
                            )
                          }
                        />
                        <YMTinyButton
                          color="white"
                          size="xs"
                          onClick={() => {
                            setCurrentTagName(stat.matches[selectedIndex[0]]);
                            setRenameTagModal(true);
                          }}
                          text={"Renommer le tag"}
                          icon={
                            <MdEdit className={"h-6 w-6 pr-2 indigo-500"} />
                          }
                        />
                      </>
                    )}
                  </div>

                  {statKind == "token" && (
                    <>
                      <Modal
                        dismissible
                        show={mergeTagModal}
                        onClose={() => setMergeTagModal(false)}
                      >
                        <Modal.Header>Grouper les tags</Modal.Header>
                        <Modal.Body>
                          <div className="space-y-2">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                              Les tags sélectionnés seront regroupés en un seul
                              tag. Sélectionner le tag qui doit être gardé.
                            </p>
                            <div className={"flex flex-wrap gap-2"}>
                              {selectedIndex.map((i) => (
                                <Badge
                                  className={"py-1"}
                                  color={
                                    mergeTagTargetIndex == i ? "info" : "white"
                                  }
                                  onClick={() => setMergeTargetIndex(i)}
                                  size="xs"
                                >
                                  {" "}
                                  <span>{stat.matches[i]}</span>{" "}
                                </Badge>
                              ))}
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <YMButton
                            text="Grouper"
                            onClick={() => handleMergeTags(stat.className)}
                          />
                          <YMButton
                            text="Annuler"
                            color="white"
                            onClick={() => setMergeTagModal(false)}
                          />
                        </Modal.Footer>
                      </Modal>
                      {useSearchSimilarTags.isFetched &&
                        selectedAiSuggestedTags.length == 0 && (
                          <div className={"mt-5 text-indigo-900 text-xs"}>
                            L'IA n'a pas pu trouver de tags similaires.
                          </div>
                        )}

                      {useSearchSimilarTags.isFetched &&
                        selectedAiSuggestedTags.length > 0 && (
                          <div
                            className={
                              "mt-5 border border-indigo-500 py-3 px-2 rounded"
                            }
                          >
                            <div
                              className={
                                "text-indigo-900 text-xs uppercase font-bold mb-3"
                              }
                            >
                              {" "}
                              Suggestions de l'IA{" "}
                            </div>
                            <div className={"flex flex-wrap gap-2"}>
                              {selectedAiSuggestedTags.map(
                                (tag: LexicalMatch, i) => (
                                  <Badge
                                    className={"py-1"}
                                    color={
                                      similarTagsBlackList.indexOf(
                                        tag.lexeme
                                      ) == -1
                                        ? "info"
                                        : "white"
                                    }
                                    size="xs"
                                    onClick={() =>
                                      ignoreAiSuggestionTag(tag.lexeme)
                                    }
                                  >
                                    {" "}
                                    <span>{tag.lexeme}</span>{" "}
                                    <span
                                      onClick={() =>
                                        ignoreAiSuggestionTag(tag.lexeme)
                                      }
                                      className={
                                        "ml-2 cursor-pointer text-indigo-900"
                                      }
                                    >
                                      x
                                    </span>
                                  </Badge>
                                )
                              )}
                            </div>
                            <YMButton
                              className={"mt-2"}
                              size={"xs"}
                              text={"Ajouter les tags au modèle"}
                              onClick={() => addAiSuggestedTagsToModel()}
                              disabled={updateClassWithAiSuggestion.isLoading}
                            />
                          </div>
                        )}

                      <div className={"flex flex-wrap gap-2 mt-5"}>
                        {stat.matches.map((match: any, i) => (
                          <Badge
                            className={"cursor-pointer py-1"}
                            color={
                              selectedIndex.indexOf(i) == -1 ? "gray" : "info"
                            }
                            size="xs"
                            onClick={() => handleClickOnTag(i)}
                          >
                            <span
                              className={"font-medium text-indigo-800 text-md"}
                            >
                              {match}
                            </span>
                            <span
                              className={
                                "bg-white font-normal text-indigo-800b border border-gray-200 p-0.5 ml-2 h-4 w-4"
                              }
                            >
                              {stat.counts[i]}
                            </span>
                          </Badge>
                        ))}
                      </div>
                    </>
                  )}

                  {statKind == "verbatim" && (
                    <>
                      <div
                        key={stat.className + "graphfrequency"}
                        className={"flex justify-center mt-5"}
                      >
                        <FrequencyGraphCanvas
                          height={390}
                          projectId={props.projectId}
                          dimensions={["lexique"]}
                          classes={[]}
                          nbCluster={5}
                          nbNodes={80}
                          excludeOrphans={false}
                          blacklist={[]}
                          subset={props.dimName + ":" + stat.className}
                          fontSizeRange={[15, 20]}
                          exclusionCircleRange={[20, 35]}
                        />
                      </div>
                      <YMLoader
                        message={"chargement des verbatims"}
                        loading={verbatims == "loading"}
                      >
                        {verbatims != "loading" && (
                          <div className={"mt-10"}>
                            <div className={"text-indigo-900 font-bold"}>
                              {" "}
                              Extrait du corpus
                            </div>
                            {verbatims
                              .slice(0, 10)
                              .map((v: VerbatimViewWithTagTokens) => (
                                <div className={"flex flex-col py-2"}>
                                  <div className={"text-indigo-900"}>
                                    {v.data}
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </YMLoader>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <DimensionTagPatchModal
        dimension={props.dimension}
        show={displayDimensionPatchModal}
        onClose={() => setDisplayDimensionPatchModal(false)}
        onPatched={() => {
          setDisplayDimensionPatchModal(false);
          notificationInfo(`Application des correctifs en cours`, {
            duration: 5000,
          });
          setTimeout(() => fetchStats(), 5000);
        }}
      />
      <DimensionTagRenameModal
        show={renameTagModal}
        onClose={() => setRenameTagModal(false)}
        onRenamed={() => {
          setRenameTagModal(false);
          fetchStats();
        }}
        currentTagName={currentTagName}
        dimensionName={props.dimension.name}
        projectId={props.projectId}
      />
      <Modal
        root={document.body}
        size={"7xl"}
        show={displayVerbatimModal}
        onClose={() => setDisplayVerbatimModal(false)}
      >
        <Modal.Header>
          <div className={"text-2xl font-bold text-indigo-800 flex"}>
            {" "}
            <SiCodereview />
            <div className={"pl-2"}> Verbatims</div>{" "}
          </div>
        </Modal.Header>
        <Modal.Body style={{ height: "75vh" }} className={"overflow-scroll"}>
          <div className={""}>
            {displayVerbatimModal && (
              <VerbatimList
                lemmasFilter={verbatimModalParams.lemmas}
                dimensionFilter={verbatimModalParams.dimension}
                classNameFilter={verbatimModalParams.classname}
                orderByClassScore={verbatimModalParams.orderByClassScore}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default function ProjectStatistics(props: any) {
  const [dimension, setDimension] = useState({} as any);
  const { dimName } = useParams();
  const [dimTitle, setDimTitle] = useState("" as string);
  const [searchParams] = useSearchParams();
  const _project = useProjectQuery({ refetchInterval: 0 });
  useEffect(() => {
    if (searchParams.get("name")) {
      setDimTitle(searchParams.get("dimensionTitle") as string);
    }
    let dim = _project.data?.dimensions.find((d: any) => d.name == dimName);
    if (dim) {
      setDimension(dim);
    }
  }, [searchParams, _project.data]);

  return (
    <>
      {dimName == "" ? (
        <div className="w-100 mt-10 text-center">
          <Spinner color="gray" size="xl" aria-label="Default status example" />
          <div className={"mt-4 text-gray-400"}> Fetching Stats</div>
        </div>
      ) : (
        <div>
          <YMCard className={"mb-5"}>
            {dimension && dimName && (
              <DimensionMatchesDistribution
                projectId={_project.data?.id || ""}
                title={dimTitle}
                dimName={dimName}
                dimType={dimension.job_type}
                dimModelId={dimension.model_id}
                dimension={dimension}
                description={dimension.description}
                onDeleteTags={() => {}}
              />
            )}
          </YMCard>
        </div>
      )}
    </>
  );
}
