import { IoMdAdd } from "react-icons/io";
import { YMButton, YMTinyButton } from "../../../ym-components";
import React from "react";
import { Modal } from "flowbite-react";
import { BiSupport } from "react-icons/bi";

export const ButtonPurchaseModels = () => {
  const [showModal, setShowModal] = React.useState(false);

  const onContactExpert = () => {
    // @ts-ignore
    window.$crisp.push(["do", "chat:open"]);
    // @ts-ignore
    $crisp.push([
      "set",
      "session:event",
      [
        [
          [
            "user_purchase_request",
            {
              description:
                "L'utilisateur a cliqué sur le bouton 'contacter un expert' pour acheter un modèle spécifique",
            },
            "green",
          ],
        ],
      ],
    ]);
    // @ts-ignore
    window.$crisp.push([
      "do",
      "message:send",
      ["Bonjour, je souhaite disposer d'un modèle IA exclusif sur Youmean."],
    ]);
    setShowModal(false);
  };

  return (
    <>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>Acheter un modèle exclusif</Modal.Header>
        <Modal.Body>
          <p>
            Besoin d'IA sur mesure ? <br />
            <br />
            Vous pouvez travailler sur la plateforme Youmean avec un modèle
            spécialement conçu pour votre besoin métier en
            détection-classification de mots ou de verbatims. <br />
            <br />
            Nos équipes s'occupent de l'apprentissage du modèle et de la mise en
            ligne sur votre instance. Parlons-en.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <YMButton
            text={"Contacter un expert"}
            icon={<BiSupport className={"h-4 w-4 text-white mr-2"} />}
            onClick={onContactExpert}
          />
        </Modal.Footer>
      </Modal>
      <YMTinyButton
        onClick={() => setShowModal(true)}
        icon={<IoMdAdd className={"h-4 w-4 text-white mr-2"} />}
        text={"Acheter un modèle exclusif"}
      />
    </>
  );
};
