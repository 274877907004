const d3 = require("d3");

var delta = 20;

function enhancedGraph(graph, settings) {
  const {
    height,
    width,
    onClick,
    debugMode,
    fontSizeRange,
    exclusionCircleRange,
  } = settings;

  if (!graph || !graph.nodes || !graph.edges) {
    console.error("Graph should have nodes and edges");
    return false;
  }
  if (!graph.nodes.length) {
    console.error("Graph should have non empty nodes and edges arrays");
    return false;
  }

  // Work with a copy of nodes & edges, because d3js needs to alter them
  debugMode && console.log("edges:", graph.edges);
  debugMode && console.log("nodes:", graph.nodes);
  const nodes = graph.nodes
    .filter((node) => !node.hidden)
    .map((node) => Object.assign({}, node));
  const edges = graph.edges
    .filter((edge) => !edge.hidden)
    .map((edge) => Object.assign({}, edge));

  var minFrequency = Math.min(...nodes.map((node) => node.frequency));
  var maxFrequency = Math.max(...nodes.map((node) => node.frequency));
  var minWeight = Math.min(...edges.map((edge) => edge.weight));
  var maxWeight = Math.max(...edges.map((edge) => edge.weight));

  debugMode && console.log("height:", height);
  debugMode && console.log("width:", width);
  debugMode && console.log("minFrequency:", minWeight);
  debugMode && console.log("maxFrequency:", maxWeight);
  var fontSize = d3
    .scaleLinear()
    .domain([minFrequency, maxFrequency])
    .range(fontSizeRange);
  var exclusionCircle = d3
    .scaleLinear()
    .domain([minFrequency, maxFrequency])
    .range(exclusionCircleRange);
  var linkStrength = d3
    .scaleLinear()
    .domain([minWeight, maxWeight])
    .range([0.1, 0.2]);
  var linkSize = d3.scaleLinear().domain([minWeight, maxWeight]).range([1, 10]);

  var color = d3.scaleOrdinal(d3.schemeCategory10);

  var svg = d3.select("#corpusgraph");
  // Clear all childs
  svg.selectAll("*").remove();

  var link = svg
      .append("g")
      .attr("class", "links")
    .selectAll("line")
    .data(edges)
    .enter()
    .append("path")
    .attr("stroke-width", function (d) {
      return linkSize(d.weight);
    })
    .attr("stroke", function (d) {
      return color(d.group);
    });

  var node = svg
    .append("g")
    .attr("class", "nodes")
    .selectAll("g")
    .data(nodes)
    .enter()
    .append("g");

  node
    .append("text")
    .text(function (d) {
      return d.lemma;
    })
    .attr("x", 0)
    .attr("y", 0)
    .attr("cursor", "grab")
    .attr("font-size", function (d) {
      return fontSize(d.frequency);
    })
    .attr("fill", function (d) {
      return color(d.group);
    })
    .on("click", setCluster(nodes))
    .call(
      d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended)
    );

  node.append("title").text(function () {
    return "Cliquer pour voir le datalab de ce groupe de mots";
  });

  var simulation = d3
    .forceSimulation()
    .force(
      "link",
      d3.forceLink().id(function (d) {
        return d.lemma;
      })
    )
    .force(
      "forceY",
      d3
        .forceY()
        .strength(0.3)
        .y(height * 0.5)
    )
    .force(
      "forceX",
      d3
        .forceX()
        .strength(0.05)
        .x(width * 0.5)
    )
    .force("charge", d3.forceManyBody())
    .force("center", d3.forceCenter(width / 2, height / 2));

  simulation
    .nodes(nodes)
    .force(
      "collide",
      d3
        .forceCollide()
        .strength(0.4)
        .radius(function (d) {
          return exclusionCircle(d.frequency);
        })
        .iterations(3)
    )
    .on("tick", ticked);

  simulation
    .force("link")
    .links(edges)
    .strength((link) => linkStrength(link.weight) + (link.sameGroup ? 0.2 : 0));

  function keepInViewX(x) {
    const marginX = 5;
    if (x < marginX) return marginX;
    if (x + marginX > width) return Math.max(0, width - marginX);
    return x;
  }

  function keepInViewY(y) {
    const marginY = 5;
    if (y < marginY) return marginY;
    if (y + marginY > height) return Math.max(0, height - marginY);
    return y;
  }

  function ticked() {
    link
      .attr("x1", function (d) {
        return keepInViewX(d.source.x);
      })
      .attr("y1", function (d) {
        return keepInViewY(d.source.y);
      })
      .attr("x2", function (d) {
        return keepInViewX(d.target.x);
      })
      .attr("y2", function (d) {
        return keepInViewY(d.target.y);
      })
      .attr("d", positionLink);

    node.attr("transform", function (d) {
      return "translate(" + keepInViewX(d.x) + "," + keepInViewY(d.y) + ")";
    });
  }

  function dragstarted(d) {
    if (!d3.event.active) simulation.alphaTarget(0.5).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(d) {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  }

  function dragended(d) {
    if (!d3.event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  function setCluster(nodeWithFrequencies) {
    return function (d) {
      if (
        d &&
        nodeWithFrequencies &&
        Array.isArray(nodeWithFrequencies) &&
        nodeWithFrequencies.length
      ) {
        const group = d && d.group;
        // List of words that belong to the same cluster as the clicked node
        const clusters = nodeWithFrequencies
          .filter((node) => node.group === group)
          .map((node) => node.lemma);
        // Details about the selected node

        onClick && onClick({ clusters, node: d });
      }
    };
  }

  function positionLink(d) {
    return (
      "M" +
      keepInViewX(d.source.x) +
      "," +
      keepInViewY(d.source.y) +
      "Q" +
      Math.ceil((d.source.x + d.target.x) / 2 + delta) +
      "," +
      Math.ceil((d.source.y + d.target.y) / 2 + delta) +
      " " +
      keepInViewX(d.target.x) +
      "," +
      keepInViewY(d.target.y)
    );
  }
}

export default enhancedGraph;
