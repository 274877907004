import { YMInput, YMSelect } from "../../../ym-components";
import { ColorPicker } from "../components/ColorPicker";
import React, { useEffect, useState } from "react";
import { ProjectDimension, TagType } from "../../../app_client";

export interface WordCloudParams {
  wordcloudClassCount: number;
  wordCloudFontDisparity: number;
  width: number;
  height: number;
  wordCloudFontMin: number;
  wordCloudFontMax?: number;
  color: string;
  classColors: Record<string, string>;
}

export const WordCloud = ({ dimension, handleParamsChange }: { dimension: ProjectDimension; handleParamsChange?: (params: WordCloudParams) => void }) => {
  const [params, setParams] = useState<WordCloudParams>({
    wordcloudClassCount: 15,
    wordCloudFontDisparity: 0.5,
    wordCloudFontMin: 12,
    width: 1800,
    height: 800,
    color: "indigo",
    classColors: generateInitialClassColors(dimension),
  });

  useEffect(() => {
    if (handleParamsChange) {
      handleParamsChange(params);
    }
  }, [params, handleParamsChange]);

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"w-80"}>
          {" "}
          <YMInput
            label={"Nombre de mots par classe"}
            type="number"
            value={params.wordcloudClassCount}
            onChange={(v) => setParams({ ...params, wordcloudClassCount: parseInt(v) || params.wordcloudClassCount })}
          />{" "}
        </div>
        <div className={"w-40"}>
          {" "}
          <YMInput
            label={"Font Min"}
            type="number"
            value={params.wordCloudFontMin}
            onChange={(v) => setParams({ ...params, wordCloudFontMin: parseInt(v) || params.wordCloudFontMin })}
          />{" "}
        </div>
        <div className={"w-80"}>
          <YMSelect
            label={"Disparité de la taille des mots "}
            value={params.wordCloudFontDisparity.toString()}
            onChange={(v: string) => setParams({ ...params, wordCloudFontDisparity: parseFloat(v) || params.wordCloudFontDisparity })}
            options={[
              { id: "0.001", name: "Minimale" },
              { id: "0.5", name: "Modérée" },
              { id: "1", name: "Maximale" },
            ]}
          />{" "}
        </div>
      </div>
      {(dimension.mono_class || dimension.tag_type == TagType.TEXT_CLASSIFICATION) ? (
        <div className="flex flex-row gap-4">
          <div className={"flex gap-2 items-center"}>
            <div className="text-md text-indigo-900"> {dimension.name}</div>
            <div className={"mt-2"}>
              {" "}
              <ColorPicker color={params.color} handleColorPicked={(c) => setParams({ ...params, color: c })} />{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row flex-wrap gap-4">
          {(dimension.class_names || []).map((classe) => (
            <div className={"flex gap-2 items-center"}>
              <div className="text-md text-indigo-900"> {classe}</div>
              <div className={"mt-2"}>
                {" "}
                <ColorPicker
                  color={(params.classColors[classe] === 'hidden' ? 'white' : params.classColors[classe]) || "white"}
                  handleColorPicked={(c) =>
                    setParams({
                      ...params,
                      classColors: {
                        ...params.classColors,
                        [classe]: c === "white" ? 'hidden' : c,
                      }
                    })
                  }
                />{" "}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

function generateInitialClassColors(dimension: ProjectDimension): Record<string, string> {
  if (dimension.mono_class) {
    return {};
  }
  return (dimension.class_names || []).reduce((acc, class_name) => {
    acc[class_name] = "indigo";
    return acc;
  }, {} as Record<string, string>);
}